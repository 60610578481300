import { CSSProperties, ReactNode } from 'react'
import { ButtonLinkSecondaryArrow } from '@/components/button'
import { ClockIcon, EuroIcon, LocationIcon } from '@/components/icons'
import { P2 } from '@/components/typography'
import { VacancyDetailsCard } from '@/interfaces/vacancy'
import Block from '@/layouts/block'
import { Divider, DividerColors } from '@/components/divider'
import { routes } from '@/lib/constants'
import useIsRay from '@/hooks/useIsRay'
import Image from '@/components/image'
import Link from '@/components/link'
import InnerHtml from '@/services/contentful/utils/innerHtml'
import { VacancyTagCard } from '@/components/vacancy-tag'

type VacancyCardProps = VacancyDetailsCard & {
  className?: string
  order: number
}

const VacancyCardInfo = ({
  iconComponent,
  text,
}: {
  iconComponent: ReactNode
  text: string
}) => (
  <div className="vacancyCard__info">
    {iconComponent}
    <P2 className="ml-2">{text}</P2>
  </div>
)

const VacancyCardDescription = ({
  title,
  location,
  salary,
  hoursPerWeek,
  description,
  href,
}: Omit<
  VacancyCardProps,
  'uid' | 'photoFileName' | 'order' | 'publicationStatusId'
> & {
  href: string
}) => (
  <div className="vacancyCard__description">
    <Link href={href}>
      <h3 className="H2">{title}</h3>
    </Link>
    {location && (
      <VacancyCardInfo iconComponent={<LocationIcon />} text={location} />
    )}
    {salary && <VacancyCardInfo iconComponent={<EuroIcon />} text={salary} />}
    {hoursPerWeek && (
      <VacancyCardInfo
        iconComponent={<ClockIcon />}
        text={`${hoursPerWeek} per week`}
      />
    )}
    <InnerHtml
      className="vacancyCard__description-text"
      innerHtml={description || ''}
    />
  </div>
)

const VacancyCardCTA = ({
  photoFileName,
  href,
}: {
  photoFileName: string
  href: string
}) => (
  <div
    className={`vacancyCard__cta justify-${
      photoFileName ? 'between' : 'end'
    }  items-${photoFileName ? 'center' : 'end'}`}
  >
    {photoFileName && (
      <Image
        height={40}
        width={200}
        src={photoFileName}
        objectFit="contain"
        alt="Company logo"
        className="self-end"
      />
    )}
    <ButtonLinkSecondaryArrow href={href} />
  </div>
)

const VacancyCard = ({
  className = '',
  photoFileName = '',
  title,
  location,
  description,
  uid,
  salary,
  hourlyRate,
  hoursPerWeek,
  order,
  publicationStatusId,
}: VacancyCardProps) => {
  const isRay = useIsRay()
  const href = isRay
    ? `${routes.ray.vacancies}/${uid}`
    : `${routes.rvdb.vacancies}/${uid}`

  return (
    <Block
      className={`vacancyCard ${className}`}
      style={{ '--order': order } as CSSProperties}
    >
      {publicationStatusId && (
        <VacancyTagCard publicationStatusId={publicationStatusId} />
      )}
      <VacancyCardDescription
        title={title}
        location={location}
        salary={salary}
        hourlyRate={hourlyRate}
        hoursPerWeek={hoursPerWeek}
        description={description}
        href={href}
      />
      <Divider className="vacancyCard__divider" color={DividerColors.BLACK} />
      <VacancyCardCTA href={href} photoFileName={photoFileName} />
    </Block>
  )
}

export default VacancyCard
